import React, { useState } from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import res1 from '../assets/images/res1.jpg';
import res2 from '../assets/images/res2.jpg';
import res3 from '../assets/images/res3.jpg';
import res4 from '../assets/images/res4.jpg';
import res5 from '../assets/images/res5.jpg';
import bridge from '../assets/images/bridge.jpg';
import logo from '../assets/img/logo.png';
import config from '../../config';

const IndexPage = () => {
  const [submitted, setSubmitted] = useState(false);
  const [state, setState] = React.useState({});

  function encode(data) {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
  }

  const handleChange = e => {
    console.log(state);
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => setSubmitted(true))
      .catch(error => alert(error));
  };

  return (
    <Layout>
      <section id="banner">
        <div className="inner">
          <div>
            <img
              src={logo}
              alt="5 Park Bay and Ocean Residences - Miami Beach, FL"
              style={{ maxWidth: '100%' }}
            />
          </div>
          {/*<h2>{config.heading}</h2>*/}
          <p style={{ color: 'black' }}>{config.subHeading}</p>
          <ul className="actions special">
            <li>
              <Scroll type="id" element="one">
                <a href="/#" className="button primary">
                  Explore
                </a>
              </Scroll>
            </li>
          </ul>
        </div>
        <Scroll type="id" element="one">
          <a href="#one" className="more">
            Learn More
          </a>
        </Scroll>
      </section>

      <section id="one" className="wrapper style1 special">
        <div className="inner">
          <header className="major">
            <h2>A NEW COMMUNITY</h2>
            <p style={{ color: 'white' }}>
              FOR PEOPLE WHO CONNECT DEEPER, SOAR HIGHER & LAUGH
              <br />
              {" "}MORE PURELY WITH THE DEPTH OF THE OCEAN THAT SURROUNDS THEM.
              <br />
            </p>
          </header>
        </div>
      </section>

      <section id="two" className="wrapper alt style5">
        <section className="spotlight">
          <div className="image">
            <img src={pic1} alt="" />
          </div>
          <div className="content">
            <h2>
              THE VERY BEST
              <br />
              {" "}OF THE BAY & OCEAN
            </h2>
            <p>
              Five Park unites timeless glamour with a new, multidisciplinary
              approach to development. The site pairs a soaring residential
              building — the tallest in Miami Beach — with an expansive
              three acre public park, tasteful retail inclusion, and the creation
              of an art-inspired bridge that connects the community south of
              fifth into a walkable wonderland surrounded by Florida’s pristine
              waters.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={pic2} alt="" />
          </div>
          <div className="content">
            <h2>
              BEACH LIVING
              <br />
              WITH AN INCOMPARABLE BACKDROP
            </h2>
            <p>
              Helmed by renowned architecture and planning studio,
              Arquitectonica, Five Park represents the intersection of function,
              beauty, and sustainability. This cylindrical marvel draws its
              curvilinear form from the undulating waves that reflect the
              ecology and culture of Miami Beach.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={pic3} alt="" />
          </div>
          <div className="content">
            <h2>
              AS YOUR PRIVATE ELEVATOR OPENS,
              <br />
              YOU KNOW YOU’VE OFFICIALLY ARRIVED.
            </h2>
            <p>
              Intelligent floor plates change and adapt to any whim, creating a
              seamless experience piqued by personalization. Clean lines and
              timeless fixtures lend an air of modernity to classic undertones,
              punctuated by pops of vibrance inspired by Miami Beach itself.
            </p>
          </div>
        </section>
      </section>

      <section id="three" className="wrapper style2 special">
        <h2>Get to know the Residences</h2>
        <div className="box alt">
          <div className="row gtr-50 gtr-uniform">
            <div className="col-6">
              <span className="image fit">
                <img src={res1} alt="" />
              </span>
            </div>
            <div className="col-6">
              <span className="image fit">
                <img src={res2} alt="" />
              </span>
            </div>
            <div className="col-6">
              <span className="image fit">
                <img src={res3} alt="" />
              </span>
            </div>
            <div className="col-6">
              <span className="image fit">
                <img src={res4} alt="" />
              </span>
            </div>
            <div className="col-6">
              <span className="image fit">
                <img src={res5} alt="" />
              </span>
            </div>
            <div className="col-6">
              <span className="image fit">
                <img src={bridge} alt="" />
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper style5">
        <div className="inner">
          <div className="row">
            <img
              className="align-center"
              src={logo}
              alt="5 Park Bay and Ocean Residences - Miami Beach, FL"
              style={{
                maxWidth: '80%',
                maxHeight: '200px',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: 'auto',
                marginBottom: '3em',
              }}
            />
          </div>
          <section>
            {submitted ? (
              <div className="col-12" style={{textAlign: "center"}}>
              <p>
                THANK YOU FOR SUBMITTING AN INQUIRY. AN AGENT WILL CONTACT YOU
                SHORTLY!
              </p>
              </div>
            ) : (
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <div className="row gtr-uniform">
                  <div className="col-6 col-12-xsmall">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      defaultValue=""
                      placeholder="First Name"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-6 col-12-xsmall">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      defaultValue=""
                      placeholder="Last Name"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-6 col-12-xsmall">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      defaultValue=""
                      placeholder="Email"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-6 col-12-xsmall">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      defaultValue=""
                      placeholder="Phone"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <select
                      name="preference"
                      id="preference"
                      onChange={handleChange}
                      required
                    >
                      <option value="">- Contact Preference -</option>
                      <option value="Any">Any</option>
                      <option value="Email">Email</option>
                      <option value="Phone">Phone</option>
                      <option value="Text">Text</option>
                    </select>
                  </div>
                  <div className="col-12">
                    <ul className="actions">
                      <li>
                        <input type="submit" value="Inquire Now" />
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            )}
          </section>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
